import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Avatar, theme, Space } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

const { Header } = Layout;

const AppHeader: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const { token } = theme.useToken();

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      setUser(JSON.parse(userInfo));
      console.log("userInfo", userInfo);
    } else {
      navigate("/auth");
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("authToken");
    navigate("/auth");
  };

  const avatarMenu = {
    items: [
      {
        key: "logout",
        icon: <LogoutOutlined />,
        label: "退出登录",
        onClick: handleLogout,
      },
    ],
  };

  const headerStyle = {
    position: "fixed" as const,
    top: 0,
    width: "100%",
    zIndex: 1,
    //padding: "0px",
    marigin: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: token.colorBgContainer,
  };

  const menuItems = [
    {
      key: "home",
      label: <Link to="/">首页</Link>,
    },
    {
      key: "history",
      label: <Link to="/history">历史记录</Link>,
    },
  ];

  if (user?.permission === 1) {
    menuItems.push({
      key: "admin",
      label: <Link to="/admin">管理面板</Link>,
    });
  }

  return (
    <Header style={headerStyle}>
      <Menu
        mode="horizontal"
        items={menuItems}
        style={{
          flex: 1,
          minWidth: "120px",
          background: "transparent",
          border: "none",
        }}
      />
      <Dropdown menu={avatarMenu} placement="bottomRight">
        <Space>
          <span style={{ color: token.colorText }}>
            {user?.username || "用户"}
          </span>
          <Avatar
            icon={<UserOutlined />}
            style={{ cursor: "pointer", backgroundColor: token.colorPrimary }}
          />
        </Space>
      </Dropdown>
    </Header>
  );
};

export default AppHeader;
