import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
} from "react-router-dom";
import App from "./App";
import Analysis from "./Analysis";
import AuthSystem from "./AuthSystem";
import BasicLayout from "./BasicLayout";
import AdminInviteCodes from "./AdminInviteCodes";
import AdminPage from "./AdminPage";
import HistoryList from "./HistoryList";

const Router = createBrowserRouter([
  {
    path: "/auth",
    element: <AuthSystem />,
  },
  {
    path: "/",
    element: (
      <BasicLayout>
        <App />
      </BasicLayout>
    ),
  },
  {
    path: "/admin",
    element: (
      <BasicLayout>
        <AdminPage />
      </BasicLayout>
    ),
  },
  {
    path: "/analysis",
    element: (
      <BasicLayout>
        <Analysis />
      </BasicLayout>
    ),
  },
  {
    path: "/history",
    element: (
      <BasicLayout>
        <HistoryList />
      </BasicLayout>
    ),
  },
]);

export default Router;
